<template>
  <h2>音声データ管理</h2>
  <pv-data-table ref="dt" :value="subjects" :rows="10" :totalRecords="totalRecords"
    :loading="loading" paginator lazy @page="loadSubjects" @sort="loadSubjects" sortField="created_at" :sortOrder="-1">
    <template #header>
      <div class="flex justify-content-start">
        <pv-input v-for="id in ids" class="ml-1" :label="id.name" v-model="cond.attrs[id.id]" :key="id.id" float icon="search" />
        <pv-input v-for="attr in attrs" class="ml-1" :label="attr.name" v-model="cond.attrs[attr.id]" :key="attr.id" float icon="search" />
        <pv-calendar class="ml-2" label="最終登録日" v-model="cond.created_at" format="yy/mm/dd" float icon />
        <pv-calendar class="ml-1" label="データ取得日" v-model="cond.recorded_at" format="yy/mm/dd" float icon />
        <pv-calendar class="ml-1" label="判定日" v-model="cond.judged_at" format="yy/mm/dd" float icon />
        <pv-dropdown class="ml-2" v-model="cond.result" :options="SubjectResults" optionLabel="label" optionValue="value" placeholder="判定結果">
          <template #value="{value}">
            <i v-if="value == 'low'" class="pi pi-sun text-pink-500 ml-2"></i>
            <i v-else-if="value == 'middle'" class="pi pi-cloud text-gray-400 ml-2"></i>
            <i v-else-if="value == 'high'" class="pi pi-bolt text-yellow-500 ml-2"></i>
          </template>
          <template #option="{option}">
            <i v-if="option.value == 'low'" class="pi pi-sun text-pink-500"></i>
            <i v-else-if="option.value == 'middle'" class="pi pi-cloud text-gray-400"></i>
            <i v-else-if="option.value == 'high'" class="pi pi-bolt text-yellow-500"></i>
          </template>
        </pv-dropdown>
      </div>
    </template>
    <template v-if="labels.length > 0">
      <pv-column v-for="id in ids" :field="'attr:'+id.id" :header="id.name" :key="id.id" sortable>
        <template #body="{data}">
          {{ getAttr(data.attrs, id)?.value }}
        </template>
      </pv-column>
      <pv-column v-for="attr in attrs" :field="'attr:'+attr.id" :header="attr.name" :key="attr.id" sortable>
        <template #body="{data}">
          {{ getAttr(data.attrs, attr)?.value }}
        </template>
      </pv-column>
    </template>
    <pv-column v-else header="ファイル名">
      <template #body="{data}">
        {{ data.voice?.filename }}
      </template>
    </pv-column>
    <pv-column field="created_at" header="最終登録日" sortable>
      <template #body="{data}">
        {{ data.voice?.created_at ? dayjs(data.voice?.created_at).format('YYYY/MM/DD HH:mm') : dayjs(data.created_at).format('YYYY/MM/DD HH:mm') }}
      </template>
    </pv-column>
    <pv-column field="recorded_at" header="データ取得日" sortable>
      <template #body="{data}">
        {{ data.voice?.recorded_at ? dayjs(data.voice?.recorded_at).format('YYYY/MM/DD HH:mm') : '---' }}
      </template>
    </pv-column>
    <pv-column field="judged_at" header="判定日" sortable>
      <template #body="{data}">
        {{ data.voice?.judged_at ? dayjs(data.voice?.judged_at).format('YYYY/MM/DD HH:mm') : '---' }}
      </template>
    </pv-column>
    <pv-column field="role" header="判定">
      <template #body="{data}">
        <pv-button v-if="data.voice.result == 1" icon="pi pi-sun" class="p-button-danger p-button-rounded cursor-auto" />
        <pv-button v-else-if="data.voice.result == 2" icon="pi pi-cloud" class="p-button-secondary p-button-rounded cursor-auto" />
        <pv-button v-else-if="data.voice.result == 3" icon="pi pi-bolt" class="p-button-warning p-button-rounded cursor-auto" />
        <pv-button v-else icon="pi pi-question" label="判定中" class="p-button-secondary p-button-text cursor-auto" />
      </template>
    </pv-column>
    <pv-column field="id">
      <template #header>
        <pv-button class="p-button-warning" icon="pi pi-download" v-tooltip="'データをダウンロード'" @click="downloadSubjects" />
      </template>
      <template #body="{data}">
        <pv-button class="ml-2 p-button-text" icon="pi pi-pencil" @click="editSubject(data)" />
        <pv-button class="ml-2 p-button-danger p-button-text" icon="pi pi-trash" @click="removeSubject(data)" />
        <pv-button v-if="data.playing" class="ml-2 p-button-success p-button-text" icon="pi pi-stop" @click="data.pause" />
        <pv-button v-else class="ml-2 p-button-success p-button-text" icon="pi pi-play" @click="playSubject(data)" />
      </template>
    </pv-column>
  </pv-data-table>

  <subject-dialog v-model="subjectDialog" :subject="subject" @change="loadSubjects(subjectParams)" />

  <subject-delete-dialog v-model="confirmDelete" :subject="delSubject" @delete="loadSubjects(subjectParams)" />
</template>

<style scoped>
::v-deep(.p-column-header-content) {
  position: relative;
}
::v-deep(th:last-of-type > .p-column-header-content) {
  float: right;
}
::v-deep(.p-column-header-content > .p-sortable-column-icon) {
  position: absolute;
  left: -27px;
}
::v-deep(.p-float-label > .p-inputtext) {
  width: 150px;
}
::v-deep(.p-dropdown),
::v-deep(.p-calendar) {
  width: 150px;
  max-height: 2.5em;
}
::v-deep(.field > .p-float-label > label) {
  font-weight: normal!important;
}
</style>

<script>
import { ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useClientLabels, useSubject, getSubjects } from "@/common/api";
import { SubjectResults } from "@/common/master";
import SubjectDialog from "@/components/SubjectDialog.vue";
import SubjectDeleteDialog from "@/components/SubjectDeleteDialog.vue";
import dayjs from "dayjs";

export default {
  layout: "app",
  components: {
    SubjectDialog,
    SubjectDeleteDialog,
  },
  setup() {
    const store = useStore();
    const { labels, ids, attrs, getAttr } = useClientLabels();

    const dt = ref();
    const loading = ref(false);
    const totalRecords = ref(0);
    const subjects = ref([]);
    const subjectParams = ref({});

    onMounted(async () => {
      await loadSubjects({
        first: 0,
        rows: dt.value.rows,
        sortField: "created_at",
        sortOrder: -1,
      });
    });

    async function loadSubjects(params) {
      if (!store.getters.isLogin) return;
      loading.value = true;
      params.created_at = cond.value.created_at ? dayjs(cond.value.created_at).format("YYYY-MM-DD") : "";
      params.recorded_at = cond.value.recorded_at ? dayjs(cond.value.recorded_at).format("YYYY-MM-DD") : "";
      params.judged_at = cond.value.judged_at ? dayjs(cond.value.judged_at).format("YYYY-MM-DD") : "";
      params.attrs = cond.value.attrs;
      params.result = cond.value.result;
      subjectParams.value = params;
      let data = await getSubjects(params);

      subjects.value = data.subjects;
      totalRecords.value = data.total;
      loading.value = false;
    }

    const cond = ref({ attrs: {}, result:"" });

    watch(() => cond.value, async () => {
      await loadSubjects(subjectParams.value);
    }, { deep: true });

    function downloadSubjects() {
      location.href = "/api/subjects/download/csv";
    }

    const { subject, editSubject, delSubject, subjectDialog, confirmDelete, removeSubject, playSubject } = useSubject();

    return {
      labels, ids, attrs, cond, SubjectResults,
      dt, loading, totalRecords, subject, subjects, subjectParams,
      subjectDialog, confirmDelete, delSubject, removeSubject,
      loadSubjects, editSubject, downloadSubjects, playSubject, getAttr,
    };
  }
}
</script>
